import { FormikProps } from 'formik';

type LoginButtonProps = {
  formik: FormikProps<{ email: string; password: string; remember: boolean }>;
  children: React.ReactNode;
};

const LoginButton: React.FC<LoginButtonProps> = ({ formik, children }) => {
  const isDisabled = (['email', 'password'] as Array<keyof typeof formik.values>).some(
    (field) => !formik.values[field] || formik.errors[field]
  );

  return (
    <button
      type='submit'
      className={`w-96 text-white font-bold py-2 px-4 rounded ${
        isDisabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-[#07779E] hover:bg-[#0892B2]'
      }`}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default LoginButton;
