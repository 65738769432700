import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material/styles';

import NotFound from 'pages/NotFound';
import Dashboard from 'pages/Dashboard';
import ParametersPage from 'pages/ParametersPage';
import LoginPage from 'pages/Login/LoginPage';
import Orders from 'pages/Orders';
import Cart from 'pages/Cart';

import ProtectedRoute from 'features/ProtectedRoute';
import PageLayout from 'features/PageLayout';
import { ResponseInterceptor } from 'features/ResponseInterceptor';

import muiTheme from './muiTheme';
import { ROUTES } from './constants/routes';

const PUBLIC_URL = process.env.PUBLIC_URL;

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <BrowserRouter basename={PUBLIC_URL}>
        <ResponseInterceptor>
          <AppContent />
        </ResponseInterceptor>
      </BrowserRouter>

      <ToastContainer className='mt-8' hideProgressBar />
    </ThemeProvider>
  );
}

const protectedRoutes = [
  { path: ROUTES.dashboard, element: <Dashboard /> },
  { path: `${ROUTES.parameters}/:id/:mode`, element: <ParametersPage /> },
  { path: `${ROUTES.parameters}/:id`, element: <ParametersPage /> },
  { path: ROUTES.orders, element: <Orders /> },
  { path: ROUTES.cart, element: <Cart /> },
];

function AppContent() {
  return (
    <div className='h-screen overflow-auto'>
      <Routes>
      <Route path={`${ROUTES.login}`} element={<LoginPage />} index />
        {protectedRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <PageLayout>
                <ProtectedRoute>{route.element}</ProtectedRoute>
              </PageLayout>
            }
          />
        ))}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
