import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import axiosInstance from 'helpers/axios';

import LoginInput from './LoginInput';
import LoginButton from './LoginButton';
import { useEffect } from 'react';
import falcoImg from 'assets/FalcoImg.png';

const LoginFormValidation = Yup.object().shape({
  email: Yup.string().matches(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    'Invalid email'
  ),
});

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema: LoginFormValidation,
    onSubmit: (values) => {
      axiosInstance
        .post('/login', values)
        .then((response) => {
          localStorage.setItem('token', response.data.data.token);
          navigate(location.state?.from || '/', { replace: true });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  return (
    <div className='flex flex-col justify-center gap-y-20 2xl:gap-y-48'>
      <div className='flex flex-row justify-center'>
        <img src={falcoImg} alt='FalcoLogo' className='h-10' />
      </div>
      <div>
        <div className='flex flex-col justify-center'>
          <div className='text-center pb-12 flex flex-col gap-6'>
            <h1 className='text-4xl'>Willkommen zurück</h1>
            <p className='max-w-[70%] mx-auto text-sm'>
              Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um auf Ihr
              Konto zuzugreifen
            </p>
          </div>
          <div className='flex justify-center items-center'>
            <form
              onSubmit={formik.handleSubmit}
              className='flex flex-col justify-center items-center rounded'
            >
              <div className='space-y-4'>
                <LoginInput
                  id='email'
                  type='email'
                  placeholder='Email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                />
                <LoginInput
                  id='password'
                  type='password'
                  placeholder='Password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                />
              </div>
              <div className='space-y-6'>
                <div className=' pl-2 flex items-center justify-between w-full pt-2'>
                  <label htmlFor='remember' className='flex items-center'>
                    <div
                      className={`w-4 h-4 border-2 rounded ${
                        formik.values.remember
                          ? ' border-[#07779E] bg-[#07779E]'
                          : 'border-gray-500'
                      } mr-2 flex justify-center items-center relative`}
                    >
                      {formik.values.remember && (
                        <span className='text-white absolute inset-0 flex items-center justify-center'>
                          <span className='bg-[#07779E] text-xs w-full h-full flex items-center justify-center'>
                            ✓
                          </span>
                        </span>
                      )}
                    </div>
                    <span>Angemeldet bleiben</span>
                    <input
                      id='remember'
                      type='checkbox'
                      checked={formik.values.remember}
                      onChange={(e) =>
                        formik.setFieldValue('remember', e.target.checked)
                      }
                      className='hidden'
                    />
                  </label>
                  <p>Passwort vergessen?</p>
                </div>
                <LoginButton formik={formik}>Login</LoginButton>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='text-sm flex flex-col items-center space-y-1'>
        <div className='flex flex-row justify-center space-x-1'>
          <span>Haben Sie noch kein Konto?</span>
          <a className='text-[#07779E]' href='/forgotpassword'>
            Registrieren Sie sich
          </a>
        </div>
        <p>info@falco-linsen.com</p>
      </div>
    </div>
  );
};

export default Login;
