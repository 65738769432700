import { useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/SVG/Search.svg';

type SearchBarProps = {
  onSearch: (value: string) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(inputValue);
  };

  return (
    <form action='/search' className='w-full px-4' onSubmit={handleSubmit}>
      <div className='relative w-[620px] h-[56px] mx-auto'>
        <input
          type='text'
          name='q'
          className='appearance-none w-full h-[56px] p-4 font-thin text-base rounded-lg bg-[#E4E9EB] pr-10 custom-placeholder'
          placeholder='Hinted search text'
          onChange={handleInputChange}
        />
        <button
          type='submit'
          className='absolute right-4 top-1/2 transform -translate-y-1/2'
        >
          <SearchIcon className='h-8' />
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
